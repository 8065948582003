import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PlicyPage = () => (
    <Layout>
        <Seo
            title="プライバシーポリシー"
        />

        <section className="bg-secondry-light p-16">
            <div className="container max-w-5xl mx-auto">
                <div className="flex justify-around">
                    <h1 className="text-2xl text-white font-bold leading-none mb-3">プライバシーポリシー</h1>
                </div>
            </div>
        </section>

        <section className="bg-base-main p-8">
            <div className="max-w-5xl mx-auto">

                <div className="flex flex-wrap">
                    <div className="">
                        <Link to={`/`}>
                            <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
                        </Link>
                        <span className="mx-4 text-sm">></span>
                        <Link to={`/policy/`}>
                            <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">プライバシーポリシー</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-base-main p-8">
            <div className="container max-w-5xl mx-auto">

                <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
                    <div className="">
                        <div className="text-md py-8">
                            そのころわたくしは、モリーオ市の博物局に勤めて居りました。
                            十八等官でしたから役所のなかでも、ずうっと下の方でしたし俸給ほうきゅうもほんのわずかでしたが、受持ちが標本の採集や整理で生れ付き好きなことでしたから、わたくしは毎日ずいぶん愉快にはたらきました。殊にそのころ、モリーオ市では競馬場を植物園に拵こしらえ直すというので、その景色のいいまわりにアカシヤを植え込んだ広い地面が、切符売場や信号所の建物のついたまま、わたくしどもの役所の方へまわって来たものですから、わたくしはすぐ宿直という名前で月賦で買った小さな蓄音器と二十枚ばかりのレコードをもって、その番小屋にひとり住むことになりました。わたくしはそこの馬を置く場所に板で小さなしきいをつけて一疋の山羊を飼いました。毎朝その乳をしぼってつめたいパンをひたしてたべ、それから黒い革のかばんへすこしの書類や雑誌を入れ、靴もきれいにみがき、並木のポプラの影法師を大股にわたって市の役所へ出て行くのでした。
                            あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。
                            またそのなかでいっしょになったたくさんのひとたち、ファゼーロとロザーロ、羊飼のミーロや、顔の赤いこどもたち、地主のテーモ、山猫博士のボーガント・デストゥパーゴなど、いまこの暗い巨きな石の建物のなかで考えていると、みんなむかし風のなつかしい青い幻燈のように思われます。では、わたくしはいつかの小さなみだしをつけながら、しずかにあの年のイーハトーヴォの五月から十月までを書きつけましょう。
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </Layout>
)

export default PlicyPage
